import React from "react"
import PropTypes from "prop-types"
import tw, { styled } from "twin.macro"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import MovingElement from "@elements/MovingElement"

const Section = styled.section`
  ${tw`relative z-10 pb-c96 pt-c80`}
`
const TextColumn = styled.div`
  ${tw`relative flex flex-col justify-center md:min-h-20vw min-h-50vw px-offset md:pl-0 md:pr-16 xl:pr-24`}
`

const HeadlineWrap = styled.div`
  ${tw`2xl:text-5xl text-4xl font-black relative z-10 font-ubuntu font-black`}
`
const Description = styled.div`
  ${tw`relative z-10 font-normal 2xl:pr-28 lg:pr-12
	`}
  &.simple-description {
    ::before {
      ${tw`absolute top-c40 md:top-c32 2xl:top-c40 -left-5`}
      content: '/*';
    }
    &::after {
      ${tw`absolute bottom-0 -left-5`}
      content: '*/';
    }
    & span.line {
      ${tw`absolute -left-3 top-c68 md:top-c60 2xl:top-c68 bottom-8 w-px bg-black bg-gray-500`}
    }
  }
  & ul {
    li {
      ${tw`relative pl-10 mb-6`}

      &::before {
        content: "|->";
        position: absolute;
        left: 0;
        ${tw`font-firacode`}
      }
    }
  }
`

const ImageColumn = styled.div`
  ${tw`relative`}
`
const ImageWrap = styled.div`
  ${tw`relative`}
`

const WpAcfTextImageRefBlock = ({ moduleData }) => {
  const blockData = moduleData?.acfRefImageTextBlock
  const sectionAttributes = moduleData?.attributes
  const sectionId = sectionAttributes.anchor || ""
  const image = getImage(blockData.image?.localFile)
  const secondImage = getImage(blockData.secondImage?.localFile)

  return (
    <Section
      id={sectionId}
      className={`${
        blockData.imagePositionVariant === "top" ? "" : "grid md:grid-cols-2"
      }`}
    >
      {blockData.imagePositionVariant === "left" && (
        <ImageColumn className={`bigImage`}>
          {image !== null && image !== undefined && (
            <MovingElement
              speed={0.05}
              direction={{
                negative: "-",
                axis: "x",
              }}
              content={false}
              className="transition-all duration-500 ease-linear"
            >
              <ImageWrap>
                <GatsbyImage
                  style={{ width: "150%" }}
                  className="right-20  md:right-60 xl:right-80 w-150pr"
                  image={image}
                  alt=""
                />
              </ImageWrap>
            </MovingElement>
          )}
        </ImageColumn>
      )}
      {blockData.imagePositionVariant === "top" && (
        <ImageColumn
          className={`grid grid-cols-2 py-c60 2xl:pt-c60 2xl:pb-c96`}
        >
          <ImageWrap className="flex justify-end md:pr-10 -mt-40">
            {image !== null && image !== undefined && (
              <MovingElement
                speed={0.05}
                direction={{
                  negative: "-",
                  axis: "y",
                }}
                content={false}
                className="w-full ml-auto mt-auto pr-2 md:pr-0 md:pl-offsetMd md:w-2/3 transition-all duration-500 ease-in-out"
              >
                <GatsbyImage
                  className="mb-20 shadow-big"
                  image={image}
                  alt=""
                />
              </MovingElement>
            )}
          </ImageWrap>
          <ImageWrap className="flex justify-start md:w-2/3">
            {secondImage !== null && secondImage !== undefined && (
              <GatsbyImage
                className="shadow-big max-w-full"
                image={secondImage}
                alt=""
              />
            )}
          </ImageWrap>
        </ImageColumn>
      )}

      <TextColumn
        className={`${
          blockData.imagePositionVariant === "top"
            ? "md:w-1/2 ml-auto "
            : "md:my-c96"
        }`}
      >
        <HeadlineWrap>
          <p className="overline-section">{blockData.overline}</p>
          <h2 className="headline-section font-ubuntu font-bold relative">
            <span
              className="relative"
              data-start-symbol={blockData.startSymbol}
              data-end-symbol={blockData.endSymbol}
            >
              {parse(blockData.headline)}
            </span>
          </h2>
        </HeadlineWrap>
        {blockData.description !== null && (
          <Description className={`copy simple-description`}>
            {parse(blockData.description)}
            <span className="line"></span>
          </Description>
        )}
      </TextColumn>
    </Section>
  )
}

WpAcfTextImageRefBlock.propTypes = {
  moduleData: PropTypes.object,
}

WpAcfTextImageRefBlock.defaultProps = {
  moduleData: {},
}

export default WpAcfTextImageRefBlock
